@use "../../scss/variables" as v;
@import "../../scss/mixins";

.news {
  position: relative;
  overflow: hidden;
  @include midflex();
  & > * {
    z-index: 10;
  }
  &__wrap {
    width: 1107px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 440px);
    @include tablet {
      padding: 0 20px;
    }
    .background {
      width: 100%;
      position: relative;
      height: 300px;
      overflow: hidden;
      p {
        color: white;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        text-align: center;
        @include mid();
        span{
          margin-bottom: 10px;
          font-size: 12px;
        }
      }
      img {
        width: 100%;
      }
      @include tablet{
        height: 200px;
      }
    }
    .content {
      margin-top: 20px;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      img{
        max-width: 100%;
        height: auto;
      }
      ul{
        display: flex;
        li{
          cursor: pointer;
          padding: 0 10px;
        }
      }
      h2 {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #3c3c3c;
      }
      @include tablet{
        img{
          width: 100%;
          height: auto;
        }
      }
    }
  }
  .backgroundBottom {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;
    img {
      width: 100%;
    }
    @include mobile {
      bottom: -200px;
    }
  }
}
