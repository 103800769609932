@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.wrap {
  padding-bottom: 50px;
  position: relative;
  width: 48%;
  &:not(:nth-child(2n)) {
    margin-right: 4%;
    margin-bottom: 15px;
  }
  .image {
    height: 313px;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    width: 100%;
    img {
      width: 100%;
      position: absolute;
      @include mid();
      @include mobile{
        width: 227%;
      }
    }
  }
  h2 {
    padding: 25px 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #3c3c3c;
  }
  .des {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: justify;
    margin-bottom: 10px;
    color: #3c3c3c;
    @include overtext(2);
  }
}
