.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  position: relative;
  z-index: 1000;
  flex-wrap: wrap;
}

.pagination > li {
  width: 52px;
  height: 52px;
  border-radius: 5px;
  color: #3c3c3c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
  cursor: pointer;
}

.pagination > li > a {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.pagination > li.active {
  background: #005b38;
  color: white;
}
