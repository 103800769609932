@use "../../../scss/variables" as v;
@import "../../../scss/mixins";

.slider {
  position: relative;
  .content{
    top: 40%!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include mid();
    @include mobile {
      top: 50%!important;
    };
    .subTitle {
      margin-top: -120px;
      display: inline-flex;
      font-weight: 700;
      font-size: 30px;
      line-height: 42px;
      padding: 10px 20px;
      background-color: v.$green-cl;
      border-radius: 60px;
      text-align: center;
    }
    .title {
      margin-top: 60px;
      font-weight: 700;
      font-size: 90px;
      line-height: 100px;
      text-align: center;
    }
    .des {
      margin-top: 65px;
      width: 630px;
      height: 80px;
      line-height: 70px;
      font-family: 'MyFont';
      font-size: 60px;
      display: flex;
      flex-direction: column;
      align-items: space-between;
      justify-content: space-between;
      h2.right {
        text-align: right;
      }
      @include tablet{
        width: auto;
        padding: 0 20px;
        font-size: 40px;
        white-space: nowrap;
        h2.right {
          text-align: left;
        }
      }
    }
  }
  .background {
    width: 100%;
    height: 804px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #009e66;
      opacity: 0.7;
      z-index: 0;
    }
    & > * {
      z-index: 10;
    }

  }
  .listItem {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    margin-top: 83px;
    width: 957px;
    height: 268px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    @include tablet{
      display: none;
    }
    ul {
      display: flex;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 70px;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
        p {
          margin-top: 10px;
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
        }
        &:nth-child(2),
        &:nth-child(3) {
          border-left: 2px dashed white;
          border-right: 2px dashed white;
        }
      }
    }
    button {
      width: 30px;
      height: 29px;
      border: none;
      background-color: transparent;
      cursor: pointer;
      margin-top: 20px;
    }
  }
  .glass {
    bottom: -170px;
    z-index: 5;
    position: absolute;
    width: 100%;
    transform: rotateX(180deg);
    @include tablet {
      bottom: -100px;
    }
    @include mobile {
      bottom: -50px;
    }
  }
}
