/*primary colors*/
$white-cl: #fff;
$black-cl: #000;
$yellow-cl: #bea67c;
$green-cl: #009E66;
/*global text color*/
$text-cl: #010101;
$text-black-cl: #000;
$gray-cl:#464646;
/*global bg color*/
$main-bg-cl: #fff;
$black-bg-cl: #000;

// time transition
$t: 0.4s;
$cubic: cubic-bezier(0.84, 0.13, 0.33, 0.97);

/*font families*/
$font-stack: "Open Sans", sans-serif;

/*headings typo*/
$fz-text: 16px;
$lh-text: normal;

$fz-text-m: 16px;
$lh-text-m: normal;

/*GRID - media queries breakpoints*/
$xxs-min: 340px;
$xs-min: 768px;
$sm-min: 992px;
$md-min: 1200px;
$lg-min: 1600px;

$xxs-max: ($xxs-min - 1);
$xs-max: ($xs-min - 1);
$sm-max: ($sm-min - 1);
$md-max: ($md-min - 1);
$lg-max: ($lg-min - 1);

/*BOXED LAYOUT*/
$max-width: 1107px;
$container-max-width: $max-width;
$container-width: 1010px;
