@use "../../scss/variables" as v;
@import "../../scss/mixins";

.service {
  position: relative;
  overflow: hidden;
  .background {
    width: 100%;
    position: relative;
    z-index: 1;
    &::after {
      content: "";
      z-index: 1;
      width: 100%;
      height: 100%;
      background: #009e66;
      opacity: 0.3;
      position: absolute;
      top: 0;
      left: 0;
    }
    img {
      width: 100%;
    }
  }
  & > * {
    z-index: 10;
  }
  &__wrap {
    max-width: 1107px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 440px);
    margin: 0 auto;
    margin-top: -20px;
    position: relative;
    z-index: 200;
    @include tablet {
      padding: 0 20px;
    }
    .search {
      width: 100%;
      background: #19b76b;
      border-radius: 10px;
      display: flex;
      justify-content: space-around;
      height: 125px;
      column-gap: 20px;
      padding: 0 10px;
      @include tablet {
        flex-direction: column;
        height: 100%;
        padding: 20px;
        .search__field {
          width: 100% !important;
          button {
            margin-top: 10px;
          }
        }
      }
      .search__field {
        height: 60%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 20%;
        row-gap: 10px;
        margin-top: 10px;
        p {
          color: white;
        }
        input,
        select {
          background: #ffffff;
          border-radius: 20px;
          background: #ffffff;
          border-radius: 20px;
          // height: 58px;
          border: none;
          padding: 0 15px;
        }
        select {
          border: 1px solid #ccc;
          background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%2326749a'><polygon points='0,0 100,0 50,50'/></svg>")
            no-repeat;
          background-color: #ffffff;
          background-size: 10px;
          background-position: calc(100% - 20px) 17px;
          background-repeat: no-repeat;
        }
        .inputSearch{
                    height: 36px;
        }
      }
      button {
        height: 44px;
        background: #ffbd3c;
        border-radius: 20px;
        border: none;
        cursor: pointer;
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      row-gap: 20px;
      column-gap: 55px;
      margin-top: 85px;
      @include tablet {
        flex-wrap: nowrap;
        flex-direction: column;
        .item {
          width: 100% !important;
        }
      }
      .item {
        width: 30%;
        position: relative;
      }
    }
    .pagination {
      @include midflex();
      padding-bottom: 50px;
      ul {
        @include midflex();
        li {
          width: 52px;
          height: 52px;
          @include midflex();
          border-radius: 5px;
          color: #3C3C3C;
          &.active{
            background: #005b38;
            color: white;
          }
        }
      }
    }
  }
  .backgroundBottom {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;
    img {
      width: 100%;
    }
    @include mobile {
      bottom: -200px;
    }
  }
}
