@use "../../../../scss/variables" as v;
@import "../../../../scss/mixins";

.item {
  width: 32%;
  display: flex;
  flex-direction: column;
  @include tablet{
    width: 100%;
  }
  .text {
    display: flex;
    @include midflex();
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #005b38;
    background: #ffbd3c;
    border-radius: 5px;
    padding: 5px 0;
    margin-top: 25px;
  }
  img {
    width: 100%;
  }
  .des {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: justify;
    color: #3c3c3c;
    background: white;
    padding: 12px 15px;
    border-radius: 5px;
    height: 100%;
  }
}
