@use "../../scss/variables" as v;
@import "../../scss/mixins";

.news {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @include midflex();
  & > * {
    z-index: 10;
  }
  .background {
    width: 100%;
    position: relative;
    p{
      color: white;
      text-transform: uppercase;
      @include mid();
    }
    img {
      width: 100%;
    }
  }
  &__wrap {
    max-width: 1107px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 440px);
    @include tablet{
      padding: 0 20px;
    }
    .content {
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      .left {
        padding-top: 50px;
        width: 100%;
        .listItem{
          display: flex;
          flex-wrap: wrap;
        }
        @include tablet{
          padding: 0;
        }
      }
      @include mobile{
        .left{
          padding-top: 0px;
          width: 100%;
        }
      }
    }
  }
  .backgroundBottom {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;
    img {
      width: 100%;
    }
    @include mobile{
      bottom: -200px;
    }
  }
}
