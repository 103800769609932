@use "../../scss/variables" as v;
@import "../../scss/mixins";

.test{
  color:white;
  .bg{
    height: 300px;
    @include mobile{
      height: 190px;
    }
  }
  .gallery{
    background: #009E66;
    position: relative;
    width: 100%;
    @include midflex();
    & > img{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    &__wrap{
      width: v.$max-width;
      position: relative;
      z-index: 10;
      width: 1071px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 50px;
      @include mobile{
        padding: 0 20px;
      }
    }
  }
}