@use "../../../scss/variables" as v;
@import "../../../scss/mixins";
.info {
  width: 100%;
  z-index: 5;
  background: v.$green-cl;
  position: relative;
  @include midflex();
  .infoWrap {
    width: 1107px;
    display: flex;
    position: relative;
    z-index: 5;
    margin-top: 170px;
    padding: 0 20px;
    &__left {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        font-size: 30px;
        display: inline-flex;
        flex-direction: column;
        line-height: 50px;
      }
      p {
        margin-top: 40px;
        line-height: 26px;
      }
      .button {
        margin-top: 50px;
      }
    }
    &__right {
      display: flex;
      justify-content: right;
      width: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: auto;
        @include mobile{
          display: none;
        }
      }
    }
    @include mobile {
      flex-direction: column;
      align-items: center;
      margin-top: 0px;
      &__left {
        width: 100%;
        justify-content: right;
      }
      &__right {
        width: 100%;
        justify-content: right;
        img {
          margin-left: 0px;
          width: 100%;
        }
      }
    }
  }
  .glass {
    bottom: -80px;
    z-index: 1;
    position: absolute;
    width: 100%;
    @include tablet {
      bottom: 10px;
    }
    @include mobile {
      bottom: -50px;
    }
  }
  @include mobile{
    height: 800px;
  }
}
