@use '../../scss/variables' as v;
@import '../../scss/mixins';

.service {
  position: relative;
  overflow: hidden;
  .background {
    width: 100%;
    position: relative;
    z-index: 1;
    img {
      width: 100%;
    }
  }
  & > * {
    z-index: 10;
  }
  &__wrap {
    max-width: 1107px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 440px);
    margin: 0 auto;
    position: relative;
    @include tablet {
      padding: 0 20px;
    }
    .content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;
      row-gap: 20px;
      margin-top: 40px;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          font-weight: 700;
          font-size: 24px;
          line-height: 22px;
          text-transform: uppercase;
        }
        button {
          height: 60px;
          background: #009e66;
          border-radius: 10px;
          font-weight: 700;
          font-size: 24px;
          line-height: 28px;
          color: #ffffff;
          padding: 0 20px;
          border: none;
          cursor: pointer;
          animation: shake 0.5s infinite;
        }
      }
      .detail {
        display: flex;
        flex-wrap: wrap;
        row-gap: 15px;
        width: 100%;
        li {
          width: 50%;
          display: flex;
          i {
            min-width: 22px;
            height: 22px;
            background: #dcdcdc;
            opacity: 0.7;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
      }
    }
    .listService {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      li {
        &:not(:last-child) {
          margin-right: 3%;
          margin-bottom: 15px;
        }
        width: 66px;
        height: 60px;
        background: #f4f5f8;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        font-weight: 400;
        font-size: 10px;
        line-height: 20px;
        i {
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .gallery {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      li {
        width: 30%;
        height: 203px;
        &:not(:nth-child(3n)) {
          margin-right: 5%;
          margin-bottom: 15px;
        }
        .img {
          width: 100%;
          height: 100%;
          overflow: hidden;
          position: relative;
          border-radius: 20px;
          img {
            width: 120%;
            @include mid();
            @include mobile {
              width: initial;
            }
          }
        }
      }
    }
  }
}
@keyframes shake {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
