@use "../../../scss/variables" as v;
@import '../../../scss/mixins';

.background {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  overflow: hidden;
  height: 100%;
  .title {
    @include mid();
    text-align: center;
    width: 100%;
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
    h2 {
      margin-top: 15px;
      font-weight: 600;
      font-size: 34px;
      line-height: 40px;
      @include mobile {
        margin-top: 5px;
      }
    }
  }
  img {
    width: 100%;
  }
  .glass {
    transform: rotate(180deg);
    position: absolute;
    bottom: -70%;
    left: 0;
    z-index: 10;
    @include mobile {
      display: none;
    }
  }
  @include mobile {
    height: 200px;
    img {
      width: 300%;
    }
  }
}
