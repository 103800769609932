@use "../../scss/variables" as v;
@import "../../scss/mixins";

.service {
  position: relative;
  overflow: hidden;
  .background {
    width: 100%;
    position: relative;
    z-index: 1;
    img {
      width: 100%;
    }
  }
  & > * {
    z-index: 10;
  }
  &__wrap {
    max-width: 1107px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 440px);
    margin: 0 auto;
    position: relative;
    @include tablet {
      padding: 0 20px;
    }
    .content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;
      row-gap: 20px;
      margin-top: 40px;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          font-weight: 700;
          font-size: 24px;
          line-height: 22px;
          text-transform: uppercase;
        }
        button {
          height: 60px;
          background: #009e66;
          border-radius: 10px;
          font-weight: 700;
          font-size: 24px;
          line-height: 28px;
          color: #ffffff;
          padding: 0 20px;
          border: none;
          cursor: pointer;
        }
      }
      .payment{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        span{
          font-size: 12px;
          padding-top: 100px;
          padding-bottom: 60px;
        }
        .total{
          padding-top: 60px;
          padding-bottom: 30px;
          font-weight: 600;
          font-size: 40px;
          color: red;
        }
        .qrImage{
          width: 254px;
          height: 254px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          img{
            max-width: 254px;
            max-height: 254px;
          }
          .arrown{
            width: 32px;
            height: 32px;
            background: none;
            position: absolute;
          }
          .arrowTopLeft{
            top: 0;
            left: 0;
            border-left: 2px solid #009e66;
            border-top: 2px solid #009e66;
          }
          .arrowTopRight{
            top: 0;
            right: 0;
            border-right: 2px solid #009e66;
            border-top: 2px solid #009e66;
          }
          .arrowBottomLeft{
            bottom: 0;
            left: 0;
            border-bottom: 2px solid #009e66;
            border-left: 2px solid #009e66;
          }
          .arrowBottomRight{
            bottom: 0;
            right: 0;
            border-bottom: 2px solid #009e66;
            border-right: 2px solid #009e66;
          }
          p{
            color: #009e66;
            position: absolute;
            bottom: 0;
            font-size: 12px;
            font-weight: bold;
          }
        }
        .note{
          text-align: center;
          padding-bottom: 120px;
          & > span{
            color: #009e66;
            font-weight: bold;
          }
        }
      }
    }
  }
}
