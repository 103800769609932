@use "../../scss/variables" as v;
@import "../../scss/mixins";

.documents {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @include midflex();
  & > * {
    z-index: 10;
  }
  .background {
    width: 100%;
    position: relative;
    p{
      color: white;
      text-transform: uppercase;
      @include mid();
    }
    img {
      width: 100%;
    }
  }
  &__wrap {
    width: 1107px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 440px);
    @include tablet{
      width: 100%;
      padding: 0 20px;
    }
    ul{
      margin:50px 0px;
      width: 100%;
      display: flex;
      flex-direction: column;
      li{
        cursor: pointer;
        a{
          color: black;
          display: flex;
          align-items: center;
        }
        img{
          padding: 0 10px;
        }
      }
    }
  }
  .backgroundBottom {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;
    img {
      width: 100%;
    }
    @include mobile{
      bottom: -200px;
    }
  }
}
