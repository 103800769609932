@use '../../scss/variables' as v;
@import '../../scss/mixins';

.top {
  margin-top: 95px;
}
.header {
  width: 100%;
  background: v.$green-cl;
  @include midflex();
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000;
  margin-bottom: 20px;
  height: 95px;
  &__wrap {
    width: v.$max-width;
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 100%;
    @include tablet {
      padding: 0 20px;
    }
    .logo {
      cursor: pointer;
      @include tablet {
        width: 100%;
        img{
          min-width: 68px;
          min-height: 94px;
          cursor: pointer;
        }
        display: flex;
        justify-content: space-between;
      }
    }
    .menu {
      display: flex;
      align-items: center;
      &__wrap {
        display: flex;
        .link {
          margin: 0 20px;
          height: 100%;
          color: v.$white-cl;
          transition-duration: 0.4s;
          text-align: center;
          position: relative;
          @include tablet {
            display: flex;
            flex-direction: column;
            height: 100%;
            &:hover {
              border-bottom: none !important;
              transition-duration: 0.4s;
            }
          }
          &:hover {
            border-bottom: 5px solid v.$white-cl;
            transition-duration: 0.4s;
            .child {
              opacity: 1;
              visibility: visible;
              transition-duration: 0.4s;
              @include tablet{
                height: 100%;
              }
            }
          }
          &.active {
            border-bottom: 5px solid v.$white-cl;
            font-weight: bold;
            @include tablet {
              border-bottom: none !important;
            }
          }
        }
        .child {
          opacity: 0;
          visibility: hidden;
          transition-duration: 0.4s;
          position: absolute;
          z-index: 10;
          top: 40px;
          left: 0;
          width: 100%;
          white-space: nowrap;
          text-align: left;
          border-radius: 10px;
          width: 280px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          @include tablet {
            position: initial;
            width: 100%;
            margin-top: 10px;
            height: 0;
          }
          &__link {
            width: 100%;
            padding: 15px;
            color: #0c8e53;
            border-bottom: 1px solid #bcddd1;
            background-color: #e7ffe9;
            position: relative;
            cursor: pointer;
            &:hover {
              background-color: #e7ffe9;
              &:after {
                transform: translateY(-50%) rotate(135deg);
                transition-duration: 0.4s;
              }
            }
            &:after {
              content: '';
              width: 5px;
              height: 5px;
              border-right: 1px solid #0c8e53;
              border-top: 1px solid #0c8e53;
              position: absolute;
              right: 10px;
              transform: translateY(-50%) rotate(45deg);
              top: 50%;
              transition-duration: 0.4s;
            }
            @include tablet{
              align-items: center!important;
              background-color: initial!important;
              color: white;
              justify-content: center;
            }
          }
        }
      }
      @include tablet {
        width: 100%;
        transform: translate(-200%);
        flex-direction: column;
        transition: transform 0.5s ease-in-out;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: 60px;
        z-index: 1000000;
        @include tablet{
          top: 95px;
          background-color: #009E66;
          left: 0;
        }
        &__wrap {
          display: flex;
          flex-direction: column;
          padding: 0;
          .link {
            padding: 15px 0;
            width: 100%;
            color: v.$white-cl;
            transition-duration: 0.4s;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px;
            &:hover {
              border-bottom: 5px solid v.$white-cl;
              transition-duration: 0.4s;
            }
            &.active {
              border-bottom: 5px solid v.$white-cl;
              font-weight: bold;
            }
          }
        }
        &.active {
          opacity: 1;
          visibility: visible;
          width: 100%;
          display: flex;
          transform: translateX(0);
          transition: transform 0.5s ease-in-out;
        }
      }
    }
    .searchIcon{
      display: flex;
      align-items: center;
      padding: 0px 10px;
      position: relative;
      cursor: pointer;
      @include tablet{
        display: none;
      }
      .inputSearch{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 200px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        input{
          padding: 20px;
          display: flex;
          width: 100%;
          height: 100%;
          border: 1px solid #B0B0B0;
          border-radius: 10px;
        }

        @include tablet{
          display: none;
        }
      }
    }
    .search {
      display: flex;
      align-items: center;
      margin: 0 10px;
      @include tablet{
        margin: 0;
      }
      button {
        background-color: #ffbd3c;
        border: none;
        cursor: pointer;
        padding: 15px 20px;
        border-radius: 5px;
        @include tablet {
          display: none;
        }
      }
    }
    .lang{
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      height: 100%;
      width: 65px;
      cursor: pointer;
      &:hover{
        ul{
          opacity: 1;
          visibility: visible;
          background: white;
          border-radius: 5px;
          border: 1px solid rgb(231, 231, 231);
          li{
            padding: 10px;
            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
      ul{
        position: absolute;
        top: 95px;
        left: 0;
        opacity: 0;
        visibility: hidden;
        cursor: pointer;
      }
    }
  }
}

.navMobile {
  width: 88px;
  display: none;
  height: 94px;
  @include tablet {
    width: 88px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}
.hamburger {
  display: flex;
  height: 26px;
  width: 32px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hamburger .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: white;
}

.hamburger .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
  &.active {
    transform: rotate(45deg);
  }
}

.hamburger .line2 {
  transition: transform 0.2s ease-in-out;
  &.active {
    transform: scaleY(0);
  }
}

.hamburger .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
  &.active {
    transform: rotate(-45deg);
  }
}
