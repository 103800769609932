@use "../../../scss/variables" as v;
@import "../../../scss/mixins";

.title {
  display: flex;
  font-weight: 700;
  font-size: 30px;
  line-height: 42px;
  text-transform:uppercase;
  .wrap {
    display: inline-flex;
    flex-direction: column;
    background-position: left;
    background-size: contain;
    position: relative;
    z-index: 10;
    & > * {
      z-index: 10;
    }
  }
  .yellowText {
    color: #ffbd3c;
    border-bottom: 2px dashed white;
  }
  .yellowTextNoBorder {
    color: #ffbd3c;
  }
  .backgroundTitle {
    position: absolute;
    top: -50px;
    left: -50px;
    z-index: 0;
  }
}

.reverse {
  justify-content: right;
  text-align: right;
}
