@use "../../scss/variables" as v;
@import '../../scss/mixins';

.listLogo{
  @include tablet {
    display: none!important;
  }
}
.top{
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 10000;
  cursor: pointer;
}
.footer {
  width: 100%;
  background-color: #3c3c3c;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  & > img {
    width: 100%;
    height: 20px;
  }
  .outSide {
    display: flex;
    width: 100%;
    .social {
      margin-top: 55px;
      position: fixed;
      left: 30px;
      bottom: 0;
      z-index: 100000;
      cursor: pointer;
      ul {
        li {
          margin-bottom: 16px;
          img {
            width: 52px;
            height: 52px;
          }
        }
      }
      @include tablet {
        // display: none;
        left: 0px;
      }
    }
    .footerWrap {
      width: 1107px;
      display: flex;
      justify-content: space-between;
      margin-top: 70px;
      @include tablet {
        padding: 0 15px;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
      }
      .infomation {
        margin-left: 50px;
        margin-top: 20px;
        @include tablet {
          width: 100%;
          margin-left: 0px;
        }
        &__wrap { 
          ul {
            li {
              .logoLeft{
                display: flex;
                align-items: center;
                img {
                  width: 48px;
                  margin-right: 12px;
                }
                h2{
                  font-weight: bold;
                }
              }
              .logoright{
                padding-left: 60px;
                p{
                  padding-bottom: 10px;
                }
              }
            }
          }
        }
      }
      .menuGallery{
        width: 30%;
        padding-bottom: 50px;
        @include tablet {
          width: 100%;
          margin-left: 0px;
        }
        .menu {
          .listMenu {
            display: flex;
            ul {
              width: 50%;
              margin-right: 30px;
              @include tablet {
                margin-right: 0px;
              }
              @include tablet {
                width: 100%;
              }
              li {
                font-weight: 400;
                font-size: 12px;
                line-height: 26px;
                position: relative;
                margin-left: 15px;
                a{color: white;}
                &::before {
                  content: '\2192';
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  left: -20px;
                  width: 15px;
                  height: 15px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 50%;
                  background: #009e66;
                  font-size: 10px;
                }
              }
            }
          }
          @include tablet {
            display: none;
          }
        }
        .gallery {
          padding-top: 20px;
          width: 70%;
          .menuTitle{
            @include tablet {
              display: none;
            }
          }
          ul {
            margin-top: 32px;
            display: flex;
            flex-wrap: wrap;
            row-gap: 20px;
            column-gap: 20px;
                      @include tablet {
            display: none;
          }
            li {
              width: 56px;
              height: 56px;
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              cursor: pointer;
              img {
                width: 100%;
              }
            }
          }
        }
      }

    }
  }
  .contact {
    position: absolute;
    right: -50px;
    top: 50%;
    transform: rotate(-90deg);
    background-color: #009E66;
    color: white;
    padding: 10px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    cursor: pointer;
    @include tablet {
      display: none;
    }
  }
}
.copyRight{
  overflow: hidden;
  background: #009E66;
  height: 36px;
  display: flex;
  justify-content: center;
  @include tablet{
    padding: 0 20px;
  }
  .copyRightWrap{
    width: 1107px;
    color: white;
    font-size: 12px;
    padding: 10px 0;
  }
}
.menuTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img{
    cursor: pointer;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    height: 4px;
    width: 60px;
    background-color: white;
  }
}
