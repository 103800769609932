@use "../../../scss/variables" as v;
@import "../../../scss/mixins";

.animal {
  width: 100%;
  background: v.$green-cl;
  height: 700px;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  &__wrap {
    width: 1107px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include tablet{
      padding: 0 20px;
    }
    .top {
      width: 100%;
      .title {
        padding-top: 100px;
        padding-bottom: 70px;
        width: 100%;
        display: flex;
        justify-content: right;
      }
    }
    .bottom{
      display: flex;
      .left{
        width: 50%;
        position: relative;
        img{
          position: absolute;
          bottom: 0;
          left: -100px;
        }
        @include tablet{
          display: none;
        }
      }
      .right{
        width: 50%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 150px;
        position: relative;
        z-index: 200;
        .listImage{
          display: flex;
          justify-content: center;
          img{
            padding: 0 50px;
            @include tablet{
              width: 100%;
              padding: 0px;
            }
          }
        }
        @include tablet{
          width: 100%;
        }
      }
    }
  }
  .glass{
    position: absolute;
    bottom: -100px;
    transform: rotateX(180deg);
    @include mobile{
      display: none;
    }
  }
}
