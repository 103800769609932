@use "../../../scss/variables" as v;
@import "../../../scss/mixins";

.wrap {
  padding-bottom: 50px;
  .image {
    width: 100%;
    height: 313px;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      @include mid();
    }
    .sale {
      width: 25%;
      position: absolute;
      top: 0;
      left: 0;
      height: 56.63px;
      background: #ff0000;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom-right-radius: 30px;
      font-weight: 700;
      font-size: 20px;
      line-height: 22px;
      text-align: center;
      color: #ffffff;
    }
  }
  h2 {
    padding: 25px 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #3c3c3c;
  }
  .des {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: justify;
    margin-bottom: 10px;
    color: #3C3C3C;
    @include overtext(2);
  }
}
