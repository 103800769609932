@use '../../scss/variables' as v;
@import '../../scss/mixins';

.button {
  color: #ffbd3c;
  border: none;
  background: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  span {
    width: 30px;
    height: 29px;
    @include midflex();
    margin-left: 10px;
  }
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: #ffbd3c;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  &:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
}

.buttonBlack {
  color: #3c3c3c;
  border: none;
  background: none;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  padding: 0 !important;
  position: relative;
  span {
    width: 30px;
    height: 29px;
    @include midflex();
    margin-left: 10px;
  }
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: #ffbd3c;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  &:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }
}
