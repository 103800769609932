@use '../../scss/variables' as v;
@import '../../scss/mixins';

.item {
  width: 30%;
  text-align: center;
  cursor: pointer;
  .img {
    width: 100%;
    img {
      width: 100%;
      height: 240px;
      object-fit: cover;
    }
  }
  &:not(:nth-child(3n)) {
    margin-right: 5%;
    margin-bottom: 80px;
  }
  p {
    margin-top: 10px;
  }
  @include mobile{
    width: 100%;
    margin-bottom: 20px!important;
    &:not(:nth-child(3n)) {
      margin-right: initial!important;
      margin-bottom: 20px!important;
    }
  }
}
