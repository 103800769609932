.tabs {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #c2c2c2;
  li {
    padding: 10px 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 10px;
    color: #7b7b7b;
    cursor: pointer;
    &.active {
      color: white;
      background: #19b76b;
    }
    .btn {
      background-color: transparent;
      border: none;
      overflow: hidden;
      outline: none;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
}
.article {
  margin-top: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  padding-bottom: 50px;
}

.contactTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  padding-bottom: 15px;
}
.contactList {
  display: flex;
  flex-direction: column;
  & > li {
    display: flex;
    > i {
      width: 22px;
      height: 22px;
      background: #dcdcdc;
      border-radius: 50%;
      opacity: 0.7;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span {
      font-weight: bold;
    }
  }
  &__child {
    display: flex;
    li,
    i {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #0085ff;
      padding: 0 10px;
      cursor: pointer;
      img {
        padding-right: 5px;
      }
    }
  }
}

.scheduleTitle {
  margin-top: 25px;
  font-weight: bold;
}
.scheduleList {
  margin-left: 15px;
  list-style: disc !important;
}
.img{
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 100%;
  }
}