@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.tours {
  width: 100%;
  background-color: #006841;
  display: flex;
  justify-content: center;
  .wrap {
    width: 1107px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -40px;
    @include tablet {
      margin-top: 0;
      padding: 0px 20px;
    }
    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 26px;
      color: #ffbd3c;
      text-transform: uppercase;
    }
    .content {
      display: flex;
      flex-direction: column;
      p {
        font-size: 16px;
        line-height: 26px;
        background: white;
        color: #3c3c3c;
        padding: 25px 70px;
        span {
          font-weight: bold;
        }
      }
    }
    .function {
      margin-top: 65px;
      width: 100%;

      & > p {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
      }
      .listFunc {
        display: flex;
        justify-content: space-between;
        @include tablet {
          flex-direction: column;
        }
      }
    }
    .criteria {
      margin-top: 50px;
      & > p {
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        text-align: justify;
        text-transform: uppercase;
      }
      ul {
        margin-top: 20px;
        li {
          display: flex;
          width: 100%;
          flex-wrap: wrap;
          align-items: center;
          padding: 8px 0;
          span {
            width: 42px;
            height: 42px;
            background: #ffbd3c;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            flex-shrink: 1;
            color: #005b38;
            size: 17px;
          }
          & > p {
            margin-left: 20px;
            width: calc(100% - 62px);
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            text-align: justify;
          }
          & > ul {
            margin-top: 0;
            margin-left: 62px;
          }
        }
      }
    }
    .hexagon {
      p {
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        padding: 50px 0;
      }
      .top {
        display: flex;
        justify-content: center;
      }
      .bottom {
        display: flex;
        justify-content: center;
        margin-top: -60px;
      }
    }
    .table {
      padding-bottom: 100px;
      .top {
        .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 26px;
          text-align: justify;
          text-transform: uppercase;
        }
        p {
          padding: 20px 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
          text-align: justify;
        }
        ul li {
          line-height: 25px;
        }
      }
      .bottom {
        .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 26px;
          text-align: justify;
          text-transform: uppercase;
          padding-bottom: 40px;
        }
        table {
          width: 100%;
          thead {
            tr th {
              background: #ffbd3c;
              color: #005b38;
              font-weight: 700;
              font-size: 16px;
              line-height: 22px;
              vertical-align: middle;
            }
          }
          tbody {
            tr {
              td {
                background-color: white;
                color: #005b38;
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                text-align: center;
                vertical-align: middle;
                border: 2px solid #005b38;
                padding: 20px 0;
              }
            }
          }
        }
      }
    }
  }
}

.textBold {
  font-weight: bold;
}
