@use "../../../../scss/variables" as v;
@import "../../../../scss/mixins";

.hexagon:hover {
  transition: color 500ms ease-in;
  color: #ffffff;
  img {
    opacity: 1;
  }
  .imageText {
    color: white;
  }
}

.hexagon {
  z-index: 0;
  display: flex;
  width: 264px;
  height: 306px;
  position: relative;
  transition: transform 0.5s ease-out;
  transform: rotate(0deg) translateX(0px);
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  @include mobile{
    width: 158px;
    height: 183px;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    opacity: 0.5;
    z-index: 1;
  }
}

.imageText {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: justify;
  color: #BEBEBE;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
  @include mid()
}
