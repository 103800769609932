@use '../../scss/variables' as v;
@import '../../scss/mixins';

.service {
  position: relative;
  overflow: hidden;
  .background {
    width: 100%;
    position: relative;
    p {
      color: white;
      text-transform: uppercase;
      @include mid();
    }
    img {
      width: 100%;
    }
  }
  &__wrap {
    max-width: 1107px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 440px);
    margin: 0 auto;
    position: relative;
    padding-bottom: 100px;
    @include tablet {
      padding: 0 20px;
    }
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 40px;
      .number {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 30px;
        .index {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          background-color: #00a86e;
          border-radius: 50%;
          color: white;
          margin-right: 5px;
        }
      }
      .title {
        font-weight: bold;
        margin: 20px 0;
        text-align: center;
      }
      span {
        margin: 10px 0;
      }
    }
  }
}
