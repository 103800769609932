@use "../../scss/variables" as v;
@import "../../scss/mixins";

.service {
  position: relative;
  overflow: hidden;
  .background {
    width: 100%;
    position: relative;
    z-index: 1;
    img {
      width: 100%;
    }
  }
  & > * {
    z-index: 10;
  }
  &__wrap {
    max-width: 1107px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 440px);
    margin: 0 auto;
    position: relative;
    @include tablet {
      padding: 0 20px;
    }
    .content {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;
      row-gap: 20px;
      margin-top: 40px;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          font-weight: 700;
          font-size: 24px;
          line-height: 22px;
          text-transform: uppercase;
        }
        button {
          height: 60px;
          background: #009e66;
          border-radius: 10px;
          font-weight: 700;
          font-size: 24px;
          line-height: 28px;
          color: #ffffff;
          padding: 0 20px;
          border: none;
          cursor: pointer;
        }
      }
      .info{
        .total{
          color: #19B76B;
          margin-bottom: 10px;
          font-weight: bold;
          span{
            margin-left: 10px;
            color: black;
            font-size: 14px;
            font-weight: normal;
          }
        }
        h2{
          color: red;
          font-size: 40px;
          font-weight: bold;
          margin-bottom: 18px;
        }
        & > span{
          font-style: italic;
          font-size: 12px;
        }
        .address{
          font-weight: bold;
          margin-top: 10px;
        }
        .phone{
          color: #0085FF;
          font-size: 12px;
          display: flex;
          align-items: center;
          margin-top: 10px;
          cursor: pointer;
          i{
            margin-right: 10px;
          }
        }
        &__wrap{
          display: flex;
          flex-wrap: wrap;
          column-gap: 20px;
          row-gap: 10px;
          margin-bottom: 40px;
          .item{
            display: flex;
            width: 49%;
            background-color: #F4F5F8;
            border-radius: 10px;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            min-height: 60px;
            .left{
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              span{
                font-weight: 400;
                font-size: 10px;
                line-height: 22px;
                color: #B0B0B0;
              }
            }
            .right{
              display: flex;
              align-items: center;
              button{
                background: none;
                width: 12px;
                height: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #B0B0B0;
                border-radius: 50%;
                padding: 0!important;
                cursor: pointer;
              }
              .count{
                padding: 0 20px;
              }
            }
            @include tablet{
              width: 100%;
            }
          }


        }
      }
      .form{
        p{
          color: #19B76B;
          margin-bottom: 30px;
        }
        & > span{
          font-style: italic;
          font-size: 12px;
          margin-top: 15px;
          display: flex;
        }
        .formItem{
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          .label{
            color: #B0B0B0;
            font-size: 12px;
            margin-bottom: 5px;
            span{
              color: red;
            }
          }
          input{
            height: 60px;
            border: 1px solid #B0B0B0;
            border-radius: 10px;
          }
        }
        .buttonWrap{
          width: 100%;
          display: flex;
          justify-content: center;
          margin: 35px 0;
          button{
            background: linear-gradient(93.89deg, #1EAC35 21.3%, #1A8E57 75.6%);
            border-radius: 10px;
            width: 451px;
            height: 60px;
            border: none;
            color: white;
            margin: 0 auto;
            cursor: pointer;
            @include mobile{
              width: 100%;
            }
          }
        }
      }
    }
  }
}
