@use "../../scss/variables" as v;
@import "../../scss/mixins";

.news {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @include midflex();
  & > * {
    z-index: 10;
  }
  .background {
    width: 100%;
    position: relative;
    p{
      color: white;
      text-transform: uppercase;
      @include mid();
    }
    img {
      width: 100%;
    }
  }
  &__wrap {
    max-width: 1107px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 440px);
    @include tablet{
      padding: 0 20px;
    }
    .content {
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      .left {
        padding-top: 50px;
        width: 100%;
        .listIcon{
          img{
            cursor: pointer;
            padding: 20px 10px;
            @include tablet{
              width: auto!important;
            }
          }
        }
        .category{
          margin: 20px 0px;
          list-style: disc;
          li{
            padding-bottom: 10px;
            a{
              color: black;
              text-transform: uppercase;
              line-height: 23px;
            }
          }
        }
        @include tablet{
          padding: 0;
        }
      }
      .right {
        width: 405px;
        padding-top: 50px;
        .block {
          display: flex;
          width: 405px;
          flex-direction: column;
          justify-content: flex-start;
          margin-bottom: 45px;
          p {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 56px;
            background: #ffbd3c;
            border-radius: 16px 16px 0px 0px;
          }
          .listItem{
            width: 100%;
            display: flex;
            flex-direction: column;
            span{
              margin-right: 10px;
            }
            .url{
              padding: 10px 0;
              border-bottom: 1px solid #45B986;
              color: #3C3C3C;
            }
          }
        }
        @include tablet{
          width: 40%;
          padding: 0;
          .block{
            width: 100%;
          }
        }
      }
      @include mobile{
        .left{
          padding-top: 0px;
          width: 100%;
        }
        .right{
          display: none;
        }
        img{
          width: 100%;
        }
      }
    }
  }
  .backgroundBottom {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;
    img {
      width: 100%;
    }
    @include mobile{
      bottom: -200px;
    }
  }
}

.title{
  font-weight: bold;
  font-size: 16px;
}