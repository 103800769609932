@use "../../../scss/variables" as v;
@import "../../../scss/mixins";

.travel {
  background: linear-gradient(to right, #005b38, #005b38);
  position: relative;
  height: 205px;
  z-index: 1;
  display: flex;
  justify-content: center;
  &__wrap {
    width: 1107px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    .left {
      .title {
        margin-top: 30px;
      }
      .button {
        margin-top: 30px;
      }
    }
    .right{
      @include tablet{
        display: none;
      }
    }
  }
}
