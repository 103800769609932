@use "../../scss/variables" as v;
@import "../../scss/mixins";

.contact {
  position: relative;
  overflow: hidden;
  @include midflex();
  & > * {
    z-index: 10;
  }
  &__wrap {
    width: 1107px;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 440px);
    @include tablet{
      padding: 0 20px;
    }
    .background {
      width: 100%;
      position: relative;
      p {
        color: white;
        text-transform: uppercase;
        @include mid();
      }
      img {
        width: 100%;
      }
    }
    .content {
      margin-top: 20px;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      h2 {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: #3c3c3c;
      }
    }
  }
  .backgroundBottom {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 1;
    img {
      width: 100%;
    }
    @include mobile {
      bottom: -200px;
    }
  }
}

.contactWrapItem{
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include tablet {
    flex-direction: column-reverse;
  }
}

.contacts{
  margin-top: 70px;
}

.contactItem{
  margin-bottom: 70px;
}

.contactTitle {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 15px;
}
.contactList {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  @include mobile{
      white-space: initial;
  }
  & > li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    > i {
      min-width: 22px;
      min-height: 22px;
      background: #dcdcdc;
      border-radius: 50%;
      opacity: 0.7;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 12px;
    }
    span {
      font-weight: bold;
    }
  }
  &__child {
    display: flex;
    @include tablet{
      flex-wrap: wrap;
    }
    li,
    i {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #0085ff;
      padding: 0 10px;
      cursor: pointer;
      img {
        padding-right: 5px;
      }
    }
  }
}

.contactRight{
  width: 360px;
  height: 240px;
  display: flex;
  overflow: hidden;
  position: relative;
  img{
    width: 100%;
    @include mid();
  }
  @include tablet {
    width: 100%;
    margin-bottom: 20px;
  }
}