@use '../../../scss/variables' as v;
@import '../../../scss/mixins';

.item {
  width: 100%;
  background-color: #009e66;
  display: flex;
  justify-content: center;
  background-size: cover;
  .itemWrap {
    width: 1107px;
    padding: 70px 20px;
    display: flex;
    .left {
      width: 50%;
      display: flex;
      flex-direction: column;
      .content {
        padding-top: 30px;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        p {
          margin: 10px 0;
        }
        ul li span {
          font-weight: bold;
        }
      }
    }
    .right {
      width: 50%;
      display: flex;
      flex-direction: column;
      img {
        width: 100%;
      }
      .content {
        padding-top: 30px;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        p {
          margin: 10px 0;
        }
        ul li span {
          font-weight: bold;
        }
      }
    }
    @include tablet {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      padding: 0 20px;
      .left {
        width: 100%;
      }
      .right {
        width: 100%;
      }
    }
  }
  img {
    width: 100%;
  }
}

.reverse {
  flex-direction: row-reverse;
}

.flexRight {
  display: flex;
  justify-content: flex-end;
}

.backgroundGreen {
  background-color: #006841;
}
