@use "../../../scss/variables" as v;
@import "../../../scss/mixins";

.news {
  width: 100%;
  background: #077b4e;
  position: relative;
  padding-top: 60px;
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
  &__wrap {
    width: 1107px;
    padding: 0 20px;
    .title {
      width: 100%;
      margin-top: 60px;
    }
    .title2 {
      margin-top: 105px;
      @include tablet{
        width: 100%;
      }
    }
    .itemWrap {
      display: flex;
      justify-content: space-between;
      margin-top: 70px;
      flex-wrap: nowrap;
      column-gap: 20px;
      @include tablet {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    .itemWrap2 {
      margin-top: 100px;
      display: flex;
      justify-content: space-between;
      .left,
      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .center{
        @include tablet {
          img{
            width: 100%;
          }
        }
      }
      @include tablet {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
  @include tablet {
    height: auto;
  }
}

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 30%;
  cursor: pointer;
  .image {
    width: 100%;
    height: 300px;
    border-radius: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    img{
      width: 200%;
      position: absolute;
      @include mid();
    }
    @include tablet {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .titleItem {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    margin-top: 16px;
    position: relative;
    @include overtext(2);
  }
  .line {
    display: flex;
    width: 94px;
    height: 4px;
    background: #FFBD3C;
    margin-top: 20px;
  }
  .des {
    margin-top: 40px;
    line-height: 22px;
    @include overtext(3);
  }
  .button {
    margin-top: 26px;
  }
  @include tablet {
    width: 100%;
    margin-top: 50px;
  }
}

.subItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  .desTitle {
    width: 100%;
    color: #ffbd3c;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }
  .desSubItem {
    min-height: 150px;
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: justify;
  }
  .icon {
    margin-left: 50px;
  }
}

.textRight {
  text-align: right;
  .icon {
    margin-right: 50px;
  }
  @include tablet{
    text-align: left;
    .icon {
      margin-left: 50px;
    }
  }
}
